import React, { useCallback, useEffect, useRef, useState } from "react";
import { useScroll } from "react-use";
import useAssetStore from "../../../../lib/asset/redux/store";
import { useGetElementProperty } from "../../../../lib/hooks/useGetElementProperty";
import { Spinner } from "../../../ui/spinner/Spinner";
import { Campaign } from "../campaign.type";
import { FilterButtonGroup } from "../component/ButtonGroup";
import { CampaignParticipatingCard } from "../component/CampaignParticipatingCard";
import { SCROLL_PAGE_HEIGHT } from "../constant";
import {
  BackGround,
  CampaignCardWrapper,
  CenteredText,
  Text,
} from "../style/campaign-card";
import useFetchParticipatingCampaign, {
  filterParticipatingCampaigns,
  resetParticipatingCampaignData,
} from "./../redux/participatingCampaign/store";

export const CampaignParticipatingContainer: React.FC = () => {
  const participatingCategories = useFetchParticipatingCampaign(
    (state) => state.participatingCategories,
  );
  const filterOptions = ["all"].concat(participatingCategories);
  const filteredParticipatingCampaigns = useFetchParticipatingCampaign(
    (state) => state.filteredParticipatingCampaigns,
  );
  const loading = useFetchParticipatingCampaign((state) => state.loading);
  const memberRewards = useAssetStore((state) => state.memberRewards);
  const [selectedFilterOption, setSelectedFilterOption] = useState("all");
  // ページネーション
  const [pagenatedParticipatingCampaigns, setPagenatedCampaigns] = useState<
    Campaign[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // スクロール位置取得
  const targetRef = useRef(null);
  const { y } = useScroll(targetRef);
  const { getElementProperty } =
    useGetElementProperty<HTMLDivElement>(targetRef);

  const handleSelectFilterChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedValue = (e.target as HTMLButtonElement).value;
    changeFilter(selectedValue);
  };

  const changeFilter = useCallback(
    (selectedValue: string) => {
      setSelectedFilterOption(selectedValue);
      resetParticipatingCampaignData();
      filterParticipatingCampaigns(selectedValue);
    },
    [
      selectedFilterOption,
      resetParticipatingCampaignData,
      filterParticipatingCampaigns,
    ],
  );

  useEffect(() => {
    changeFilter("all");
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("scrollPosition")) {
      setTimeout(() => {
        window.scroll({
          top: Number(sessionStorage.getItem("scrollPosition")),
        });
        sessionStorage.removeItem("scrollPosition");
      }, 150);
    }
  }, []);

  useEffect(() => {
    const targetHeight = getElementProperty("height");
    if (y > targetHeight * currentPage - SCROLL_PAGE_HEIGHT) {
      setCurrentPage(currentPage + 1);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = 0;
    const currentItems = filteredParticipatingCampaigns.slice(
      indexOfFirstItem,
      indexOfLastItem,
    );
    setPagenatedCampaigns(currentItems);
  }, [y, filteredParticipatingCampaigns]);

  return (
    <>
      <FilterButtonGroup
        options={filterOptions}
        selectedOption={selectedFilterOption}
        onClick={handleSelectFilterChange}
      />
      <BackGround>
        <CampaignCardWrapper ref={targetRef}>
          {loading && <Spinner />}
          {!loading &&
            pagenatedParticipatingCampaigns.length !== 0 &&
            pagenatedParticipatingCampaigns.map((campaign: Campaign) => {
              return (
                <CampaignParticipatingCard
                  key={campaign.id}
                  campaign={campaign}
                />
              );
            })}
          {pagenatedParticipatingCampaigns.length === 0 && (
            <CenteredText>
              <Text>
                参加している <br />
                キャンペーンはありません
              </Text>
            </CenteredText>
          )}
        </CampaignCardWrapper>
      </BackGround>
    </>
  );
};
