import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { setMemberRegistrationEmail } from "../../features/member_registration/redux/store";
import { TermOfServiceContainer } from "../../features/menu/container/TermOfServiceContainer";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { Footer } from "../../ui/footer/Footer";

export const TermOfService: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [onscrollEnd, setOnScrollEnd] = useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const navigate = useNavigate();
  const onClickAgree = useCallback(() => {
    setMemberRegistrationEmail("");
    navigate("/register");
  }, []);
  const { state } = useLocation();

  const onScroll = useCallback((flg: boolean) => {
    setOnScrollEnd(flg);
  }, []);

  const footer = !state?.isMenu ? (
    <Footer
      buttonText="次へ"
      onClickButton={onClickAgree}
      isTermOfService={true}
      buttonDisabled={!(checked && onscrollEnd)}
      checked={checked}
      handleCheckBox={handleChange}
    />
  ) : undefined;

  return (
    <Base>
      <HeaderTemplate footer={footer}>
        <TermOfServiceContainer onScroll={onScroll} isMenu={!state?.isMenu} />
      </HeaderTemplate>
      {!state?.isMenu && (
        <Footer
          buttonText="次へ"
          onClickButton={onClickAgree}
          isTermOfService={true}
          buttonDisabled={!(checked && onscrollEnd)}
          checked={checked}
          handleCheckBox={handleChange}
        />
      )}
    </Base>
  );
};
