import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { settingStore } from "../../../template/store/setting";
import { RegisterMemberContent } from "../../../template/style";
import { Button, Modal, TextField } from "../../../ui";
import { ErrorMessage } from "../../../ui/textfield/style";
import { OCR } from "../../ocr";
import {
  APP_LINKAGE_SUBTEXT,
  CAMERA_CAPTURE_SUBTEXT,
  MEMBERSHIP_NUMBER_VALIDATION_TEXT,
  MEMBER_REGISTRATION_STEP_IMAGES_PATH,
} from "../constant";
import { useMemberRegister } from "../hooks/useMemberRegister";
import useMemberRegistrationStore, {
  setMemberRegistrationMembershipNumber,
} from "../redux/store";
import {
  Bg,
  ButtonContainer,
  Container,
  GuideImage,
  OCRContainer,
  StepImage,
  SubTextArea,
  SubTitle,
} from "../style";

export const MemberRegistrationStepTwoContainer: React.FC = () => {
  const navigate = useNavigate();
  const [showOCR, setShowOCR] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const membershipNumber = useMemberRegistrationStore(
    (state) => state.membershipNumber,
  );

  const guide_image = settingStore(
    (state) => state.member_code_confirm.image_url,
  );
  const guide_text = settingStore(
    (state) => state.member_code_confirm.expository_text,
  );
  const useCamera = settingStore(
    (state) => state.retail_id_input.enable_using_camera,
  );
  const useApp = settingStore(
    (state) => state.retail_id_input.enable_enter_from_app,
  );
  const retailIdInput = settingStore((state) => state.retail_id_input);
  const navigation = useNavigate();
  const { setAppLinkage, getSupayId } = useMemberRegister();

  const query = new URLSearchParams(useLocation().search);
  const error_code = query.get("error-code");
  const member_id = query.get("member_id");
  const email = useMemberRegistrationStore((state) => state.email);
  const token = useMemberRegistrationStore((state) => state.token);

  const openImageToOtherTab = () => {
    navigate("/member_code_confirm_image");
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (member_id && isLoaded) {
      window.scroll({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [isLoaded]);

  const onPrev = useCallback(() => {
    navigation(`/register/1?token=${token}&email=${email}`);
  }, []);

  const onBack = useCallback(() => {
    setIsShowModal(false);
  }, []);

  const onError = (e: string | DOMException) => {
    if (e) {
      setIsShowModal(true);
    }
  };
  return (
    <RegisterMemberContent>
      <Bg isOpen={showOCR} />
      <HeaderContainer>
        <BtnContainer onClick={onPrev}>
          <img src="/assets/common/icon_back.svg" />
        </BtnContainer>
        <h3>新規会員登録</h3>
      </HeaderContainer>

      <StepImage
        src={MEMBER_REGISTRATION_STEP_IMAGES_PATH.replace("{step}", "2")}
      />

      <SubTitle>{guide_text}</SubTitle>

      <GuideImage
        src={guide_image}
        onLoad={handleImageLoad}
        onClick={openImageToOtherTab}
      />

      {useCamera && (
        <ButtonContainer>
          <Button
            text={retailIdInput.using_camera_button_name}
            styling="border"
            color="#000000"
            onClick={() => setShowOCR(true)}
            containerStyle={{ margin: "20px 0 12px 0" }}
          />
          <SubTextArea>{CAMERA_CAPTURE_SUBTEXT}</SubTextArea>
        </ButtonContainer>
      )}
      {useApp && (
        <ButtonContainer>
          <Button
            text={
              retailIdInput.enter_from_app_button_name +
              (member_id ? "（連携済み）" : "")
            }
            styling="border"
            color="#000000"
            onClick={() => setAppLinkage("/register/2")}
            containerStyle={{ margin: "20px 0 12px 0" }}
          />
          <SubTextArea>{APP_LINKAGE_SUBTEXT}</SubTextArea>
        </ButtonContainer>
      )}
      <Container>
        {error_code && (
          <ErrorMessage>入力された会員番号が不正です</ErrorMessage>
        )}
        <TextField
          name="membership-number"
          label="会員番号"
          required
          placeholder={MEMBERSHIP_NUMBER_VALIDATION_TEXT}
          onInput={setMemberRegistrationMembershipNumber}
          value={membershipNumber}
        />
      </Container>

      {showOCR && (
        <OCRContainer>
          <OCR
            onCapture={(text) => {
              setMemberRegistrationMembershipNumber(text);
            }}
            onClose={() => setShowOCR(false)}
            onError={(e) => onError(e)}
          />
        </OCRContainer>
      )}
      <Modal
        isShow={isShowModal}
        title={"ブラウザの設定の、<br>カメラの使用を許可してください"}
        subTitle=""
        contents=""
        onClose={onBack}
      />
    </RegisterMemberContent>
  );
};
