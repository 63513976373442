import styled from "styled-components";

type Props = {
  isMenu?: boolean;
};

export const TermOfServiceFrame = styled.iframe<Props>`
  border: none;
  flex: 1;
  ${(props) => {
    if (props.isMenu) {
      return `margin-bottom: 26svh;`;
    }
  }};
`;
