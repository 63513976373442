import { create } from "zustand";

export type LoginType = "email" | "line" | "email_line";

export type SettingState = {
  header_image_url: string;
  theme_color: string;
  is_barcode_available: boolean;
  terms_of_service: string;
  login_type: LoginType;
  retail_id_input: {
    enable_using_camera: boolean;
    enable_enter_from_app: boolean;
    deep_link_url: string;
    enter_from_app_button_name: string;
    using_camera_button_name: string;
  };
  member_code_confirm: {
    image_url: string;
    expository_text: string;
  };
  line_settings: {
    allow_adding_friends: boolean;
    expository_text: string;
    transition_script: string;
    is_line_friend_required: boolean;
    line_account_management: string;
  };
  meta: {
    favicon_url: string;
    title: string;
    description: string;
    keywords: string;
    ogp: {
      image_url: string;
    };
  };
};

type SettingAction = {
  setSetting: (data: SettingState) => void;
};

export const settingStore = create<SettingState & SettingAction>()((
  set,
  get,
) => {
  return {
    header_image_url: "",
    theme_color: "",
    is_barcode_available: false,
    terms_of_service: "",
    login_type: "email",
    retail_id_input: {
      enable_enter_from_app: true,
      enable_using_camera: true,
      deep_link_url: "",
      enter_from_app_button_name: "",
      using_camera_button_name: "",
    },
    member_code_confirm: {
      image_url: "",
      expository_text: "",
    },
    line_settings: {
      allow_adding_friends: false,
      expository_text: "",
      transition_script: "",
      is_line_friend_required: false,
      line_account_management: "",
    },
    meta: {
      favicon_url: "",
      title: "",
      description: "",
      keywords: "",
      ogp: {
        image_url: "",
      },
    },
    setSetting: (data: SettingState) => set((state) => ({ ...state, ...data })),
  };
});
