import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
} from "react";
import { settingStore } from "../../template/store/setting";
import { BorderButton, ButtonSubtext, FilledButton } from "./style";

export type ButtonProps = {
  text: string;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  styling?: "filled" | "border";
  color?: string;
  onClick?: MouseEventHandler;
  subtext?: string;
  fontWeight?: string;
  containerStyle?: CSSProperties;
};

export const Button: React.FC<ButtonProps> = ({
  text,
  styling = "filled",
  disabled,
  onClick,
  subtext,
  containerStyle,
  ...props
}: ButtonProps) => {
  const handleOnClick = (e: MouseEvent) => {
    if (disabled) return;

    if (onClick) onClick(e);
  };

  const themeColor = settingStore((store) => store.theme_color);

  return (
    <div style={{ ...containerStyle }}>
      {styling === "filled" && (
        <FilledButton
          color={
            props.color
              ? props.color
              : themeColor !== ""
                ? themeColor
                : "#01469b"
          }
          disabled={disabled}
          onClick={handleOnClick}
          {...props}
        >
          {text}
        </FilledButton>
      )}
      {styling === "border" && (
        <BorderButton disabled={disabled} onClick={handleOnClick} {...props}>
          {text}
        </BorderButton>
      )}
      {subtext && <ButtonSubtext>{subtext}</ButtonSubtext>}
    </div>
  );
};
