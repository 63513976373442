import React from "react";
import { LINE } from "../../../common/icons/LINE";
import { Button } from "./../button/Button";
import { LINEButton } from "./../button/LINEButton";
import {
  Bg,
  ButtonWrapper,
  CloseBtn,
  Container,
  Image,
  Message,
  ModalIcon,
  RegisterCompleteImage,
  RegisterCompleteImageArea,
  RegisterCompleteSubTitle,
  RegisterCompleteTitle,
  SubTitle,
  Title,
} from "./style/style";

type ModalProps = {
  isShow: boolean;
  title: string;
  subTitle: string;
  image_url?: string;
  register_complete_image_url?: string;
  icon?: React.ReactElement;
  LINE?: boolean;
  contents: any;
  buttons?: {
    name: string;
    callback?: () => void;
    styling?: "filled" | "border";
    color?: string;
  }[];
  onClose(): void;
  onClick?: () => void;
  hideClose?: boolean;
  titleSize?: "sm" | "lg";
};

export const Modal: React.FC<ModalProps> = (props) => {
  return (
    <div>
      <Bg isOpen={props.isShow} />
      <Container isOpen={props.isShow}>
        <div>
          {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
          {props.title && (
            <Title
              textsize={props.titleSize ?? "lg"}
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></Title>
          )}
          <CloseBtn
            onClick={() => {
              props.onClose();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        {props.image_url && <Image src={props.image_url ?? ""} alt="sample" />}
        {props.register_complete_image_url && (
          <RegisterCompleteImageArea>
            <RegisterCompleteSubTitle>
              お買い物前に忘れずに！
            </RegisterCompleteSubTitle>
            <RegisterCompleteTitle>
              お得なキャンペーンに
              <br />
              さっそく参加しよう
            </RegisterCompleteTitle>
            <RegisterCompleteImage
              src={props.register_complete_image_url ?? ""}
              alt="sample"
            />
          </RegisterCompleteImageArea>
        )}
        {props.icon && <ModalIcon>{props.icon}</ModalIcon>}
        {props.LINE && <LINE width={75} height={75} />}
        {props.contents !== "" && (
          <Message hasIcon={props.icon ? true : false}>
            {props.contents}
          </Message>
        )}
        {props.LINE && <LINEButton onClick={props.onClick} />}
        <ButtonWrapper>
          {props.buttons?.map((button, index) => {
            return (
              <div
                key={`custom-button-${index}`}
                style={{ marginBottom: "15px" }}
              >
                <Button
                  key={button.name}
                  text={button.name}
                  styling={button.styling ? button.styling : "filled"}
                  onClick={
                    button.callback
                      ? (button.callback as any)
                      : () => {
                          /* nothing to do */
                        }
                  }
                />
              </div>
            );
          })}
          {props.hideClose !== true && (
            <Button
              text={"閉じる"}
              styling={"border"}
              color={"#000000"}
              onClick={() => props.onClose()}
            />
          )}
        </ButtonWrapper>
      </Container>
    </div>
  );
};
