import React, { useEffect, useRef, useState } from "react";
import { useScroll } from "react-use";
import { useGetElementProperty } from "../../../../lib/hooks/useGetElementProperty";
import { Spinner } from "../../../ui/spinner/Spinner";
import { Reward } from "../campaign.type";
import { EarnedFilterButtonGroup } from "../component/ButtonGroup";
import { RewardCard } from "../component/RewardCard";
import { SCROLL_PAGE_HEIGHT } from "../constant";
import {
  BackGround,
  CampaignCardWrapper,
  CenteredText,
  Text,
} from "../style/campaign-card";
import useEarnedCampaignStore, {
  fetchEarnedCampaigns,
  filterEarnedCampaigns,
  resetEarnedCampaignData,
} from "./../redux/earnedCampaign/store";

export const CampaignEarnedContainer: React.FC = () => {
  const filterOptions = ["all", "available", "used"];
  const filteredEarnedCampaigns = useEarnedCampaignStore(
    (state) => state.filteredEarnedCampaigns,
  );
  const loading = useEarnedCampaignStore((state) => state.loading);
  const [selectedFilterOption, setSelectedFilterOption] = useState("all");
  // ページネーション
  const [pagenatedEarnedReward, setPagenatedEarnedReward] = useState<Reward[]>(
    [],
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 20;
  // スクロール位置取得
  const targetRef = useRef(null);
  const { y } = useScroll(targetRef);
  const { getElementProperty } =
    useGetElementProperty<HTMLDivElement>(targetRef);

  const handleSelectFilterChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedValue = (e.target as HTMLButtonElement).value;
    setSelectedFilterOption(selectedValue);
    resetEarnedCampaignData();
    filterEarnedCampaigns(selectedValue);
  };

  useEffect(() => {
    fetchEarnedCampaigns();
  }, []);

  useEffect(() => {
    const targetHeight = getElementProperty("height");
    if (y > targetHeight * currentPage - SCROLL_PAGE_HEIGHT) {
      setCurrentPage(currentPage + 1);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = 0;
    const currentItems = filteredEarnedCampaigns.slice(
      indexOfFirstItem,
      indexOfLastItem,
    );
    setPagenatedEarnedReward(currentItems);
  }, [y, filteredEarnedCampaigns, currentPage]);

  return (
    <>
      <EarnedFilterButtonGroup
        options={filterOptions}
        selectedOption={selectedFilterOption}
        onClick={handleSelectFilterChange}
      />
      <BackGround>
        <CampaignCardWrapper ref={targetRef}>
          {loading && <Spinner />}
          {!loading &&
            pagenatedEarnedReward.length !== 0 &&
            pagenatedEarnedReward.map((reward: any) => {
              return <RewardCard key={reward.id} reward={reward} />;
            })}
          {pagenatedEarnedReward.length === 0 && (
            <CenteredText>
              <Text>
                {selectedFilterOption === "all" && "獲得した"}
                {selectedFilterOption === "available" && "利用可能な"}
                {selectedFilterOption === "used" && "利用済みの"}
                <br />
                特典はありません
              </Text>
            </CenteredText>
          )}
        </CampaignCardWrapper>
      </BackGround>
    </>
  );
};
