import { useState } from "react";
import { Labels, LineFriends } from "../../../features/campaign/campaign.type";

export const useLineModal = (lineFriends: LineFriends[]) => {
  const [filteredLineFriendsList, setFilterLineFriendsList] =
    useState<LineFriends[]>(lineFriends);
  const [firstLabels, setFirstLabels] = useState<Labels[]>([]);
  const [secondLabels, setSecondLabels] = useState<Labels[]>([]);
  const [thirdLabels, setThirdLabels] = useState<Labels[]>([]);

  const filterLineFriendsList = (
    label1: string,
    label2: string,
    label3: string,
  ) => {
    const filteredLineFriendsList = lineFriends
      .filter((lineFriend: LineFriends) => {
        const validLabels = [label1, label2, label3].filter(
          (label): label is string => label !== "",
        );

        // すべてのラベルが `undefined` の場合、すべての要素を返す
        if (validLabels.length === 0) {
          return true;
        }
        return validLabels.every((label) => lineFriend.labels.includes(label));
      })
      .sort((a: LineFriends, b: LineFriends) => {
        const aNum = Number(a.displayed_num);
        const bNum = Number(b.displayed_num);

        // `display_num === 0` を最後に配置
        if (aNum === 0) return 1;
        if (bNum === 0) return -1;
        return Number(a.displayed_num) - Number(b.displayed_num);
      });
    setFilterLineFriendsList(filteredLineFriendsList);
  };

  const filterLabels = (lineFriends: LineFriends[]) => {
    const filteredLabels = [
      ...new Set(
        lineFriends
          .map((lineFriend: LineFriends) => lineFriend.labels[0])
          .filter((label) => label !== ""),
      ),
    ].map((label) => ({
      name: label,
      value: label,
      count: lineFriends.filter(
        (lineFriend: LineFriends) => lineFriend.labels[0] === label,
      ).length,
    }));
    setFirstLabels(filteredLabels);
  };
  const filterSecondLabels = (selectedLabel1: string) => {
    const filteredLabels = [
      ...new Set(
        lineFriends
          .filter(
            (lineFriend: LineFriends) =>
              lineFriend.labels[0] === selectedLabel1,
          )
          .map((lineFriend: LineFriends) => lineFriend.labels[1])
          .filter((label) => label !== ""),
      ),
    ].map((label) => ({
      name: label,
      value: label,
      count: lineFriends.filter(
        (lineFriend: LineFriends) => lineFriend.labels[1] === label,
      ).length,
    }));
    setSecondLabels(filteredLabels);
  };
  const filterThirdLabels = (
    selectedLabel1: string,
    selectedLabel2: string,
  ) => {
    const filteredLabels = [
      ...new Set(
        lineFriends
          .filter(
            (lineFriend: LineFriends) =>
              lineFriend.labels[0] === selectedLabel1 &&
              lineFriend.labels[1] === selectedLabel2,
          )
          .map((lineFriend: LineFriends) => lineFriend.labels[2])
          .filter((label) => label !== ""),
      ),
    ].map((label) => ({
      name: label,
      value: label,
      count: lineFriends.filter(
        (lineFriend: LineFriends) => lineFriend.labels[2] === label,
      ).length,
    }));
    setThirdLabels(filteredLabels);
  };

  return {
    filteredLineFriendsList,
    filterLineFriendsList,
    filterLabels,
    filterSecondLabels,
    filterThirdLabels,
    firstLabels,
    secondLabels,
    thirdLabels,
  };
};
