import React, { ReactNode, useState } from "react";
import { IconMenu } from "../../common/icons/IconMenu";
import { useNavigate } from "../../lib/hooks/useNavigate";
import { Menu } from "../features/menu";
import { settingStore } from "./store/setting";
import {
  Header,
  HeaderTitle,
  LogoImage,
  MenuContainer,
  MenuIcon,
} from "./style";

type Props = {
  children: ReactNode;
  footer?: JSX.Element;
  preview?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const HeaderTemplate: React.FC<Props> = ({
  children,
  footer,
  preview,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const logoUrl = settingStore((store) => store.header_image_url);
  const themeColor = settingStore((store) => store.theme_color);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  return (
    <>
      <Header bg_color={`${themeColor}`} isPreview={preview || false}>
        <div>
          <HeaderTitle onClick={handleClick}>
            <LogoImage src={logoUrl} />
          </HeaderTitle>
        </div>

        <MenuIcon onClick={() => navigate("/menu")}>
          <IconMenu />
          <div>メニュー</div>
        </MenuIcon>

        {showMenu && (
          <MenuContainer>
            <Menu onClose={() => setShowMenu(false)} />
          </MenuContainer>
        )}
      </Header>
      <div
        style={{
          flexGrow: 1,
          margin: "auto",
          width: "100%",
          maxWidth: "640px",
        }}
      >
        {children}
      </div>

      {/* {footer} */}
    </>
  );
};
