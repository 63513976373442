import { FormControlLabel, Radio, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import React from "react";

type Props = {
  label: string;
  value: string;
  selectedValue: string;
  customStyle?: SxProps<Theme>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RadioButton: React.FC<Props> = ({
  label,
  value,
  selectedValue,
  customStyle,
  onChange,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio checked={selectedValue === value} onChange={onChange} />}
      label={<Typography>{label}</Typography>}
      sx={customStyle}
    />
  );
};
