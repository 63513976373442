import React, { useEffect, useState } from "react";

import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { settingStore } from "../../template/store/setting";
import {
  ButtonReturn,
  ButtonText,
  Container,
  Header,
  Image,
  ImageContainer,
} from "./style";

export const MemberCodeConfirmImage: React.FC = () => {
  const member_code_confirm = settingStore(
    (state) => state.member_code_confirm,
  );

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [imageRatio, setImageRatio] = useState<number>(1);

  useEffect(() => {
    document.documentElement.style.height = "100%";
    document.body.style.height = "100%";
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.height = "100%";
    }
    return () => {
      document.documentElement.style.height = "";
      document.body.style.height = "";
      if (rootElement) {
        rootElement.style.height = "";
      }
    };
  }, []);

  useEffect(() => {
    const img = new window.Image();
    img.src = member_code_confirm.image_url;
    img.onload = () => {
      setImage(img);
      setImageRatio(img.naturalHeight / innerHeight);
      window.scrollTo(0, 0);
    };
  }, [member_code_confirm]);

  return (
    <>
      <Container>
        <Header>
          <ButtonReturn
            onClick={() => {
              history.back();
            }}
          >
            <img src="/assets/common/icon_back.svg" />
            <ButtonText>戻る</ButtonText>
          </ButtonReturn>
        </Header>
        <ImageContainer imageRatio={imageRatio}>
          <TransformWrapper
            initialScale={1}
            minScale={1.0}
            maxScale={4}
            initialPositionX={0}
            initialPositionY={0}
            pinch={{ step: 0.1 }}
            centerZoomedOut={true}
            centerOnInit={true}
          >
            <TransformComponent
              wrapperStyle={{
                width: "100%",
                height: "100%",
              }}
            >
              <Image src={image ? image.src : ""} />
            </TransformComponent>
          </TransformWrapper>
        </ImageContainer>
      </Container>
    </>
  );
};
