import React, { useCallback, useEffect, useState } from "react";
import { LINE } from "../../../common/icons/LINE";
import { LineFriends } from "../../features/campaign/campaign.type";
import { FilterResult } from "../../features/line_friends/components/FilterResult";
import { SelectArea } from "../../features/line_friends/components/SelectArea";
import { Breadcrumb } from "../../features/line_friends/style/select-area";
import { Labels } from "../../features/line_friends/types";
import { settingStore } from "../../template/store/setting";
import { Button } from "../button/Button";
import { useLineModal } from "./hook/useLineModal";
import {
  Bg,
  ButtonLINEWrapper,
  CloseBtn,
  Container,
  Message,
  Title,
} from "./style/style";
import { ModalLINEProps } from "./types";

export const LINEModal: React.FC<ModalLINEProps> = (props) => {
  const lineSettings = settingStore((state) => state.line_settings);
  const [buttonText, setButtonText] = React.useState<string>("次へ");
  const [subButtonText, setSubButtonText] = useState<string>("閉じる");
  const [filterScreen, setFilterScreen] = React.useState<
    "start" | "filter" | "result"
  >("start");
  const [selectedLabels, setSelectedLabels] = useState<Labels>({
    label1: "",
    label2: "",
    label3: "",
  });
  const [selectedLINEFriendID, setSelectedLINEFriendID] = useState<string>("");
  const [lineFriendUrl, setLineFriendUrl] = useState<string>("");
  const {
    filteredLineFriendsList,
    filterLineFriendsList,
    filterLabels,
    filterSecondLabels,
    filterThirdLabels,
    firstLabels,
    secondLabels,
    thirdLabels,
  } = useLineModal(props.line_friends);

  const isLabelEmpty = Object.values(selectedLabels).every(
    (value) => value === "",
  );
  const onNext = useCallback(() => {
    if (filterScreen === "start") {
      setFilterScreen("result");
      setButtonText("LINEで友だち追加へ");
      setSubButtonText("友だち追加せずに次へ");
    }
    if (filterScreen === "filter") {
      setFilterScreen("result");
      setButtonText("LINEで友だち追加へ");
      setSubButtonText("友だち追加せずに次へ");
    }
    if (filterScreen === "result") {
      props.onClick && props.onClick(lineFriendUrl);
    }
  }, [filterScreen, lineFriendUrl]);

  const onWithoutLINENext = useCallback(() => {
    props.onClick && props.onClick("");
  }, [props]);

  const onFilter = useCallback(() => {
    setFilterScreen("filter");
    setButtonText("選択した条件で絞り込む");
    setSubButtonText("");
    setSelectedLINEFriendID("");
    setSelectedLabels({ label1: "", label2: "", label3: "" });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLINEFriendID(event.target.value);
  };

  useEffect(() => {
    const { label1, label2, label3 } = selectedLabels;
    filterLineFriendsList(label1, label2, label3);
    filterLabels(props.line_friends);
    filterSecondLabels(label1);
    filterThirdLabels(label1, label2);
  }, [props, selectedLabels]);

  useEffect(() => {
    const selectedLINEFriend = filteredLineFriendsList.find(
      (lineFriend: LineFriends) =>
        lineFriend.id === Number(selectedLINEFriendID),
    );
    setLineFriendUrl(selectedLINEFriend?.line_friend_url || "");
  }, [filteredLineFriendsList, selectedLINEFriendID]);

  return (
    <div>
      <Bg isOpen={props.isShow} />
      <Container isOpen={props.isShow}>
        {props.title && (
          <Title
            textsize={props.titleSize ?? "lg"}
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></Title>
        )}
        <CloseBtn
          onClick={() => {
            props.onClose();
          }}
        >
          <img src="/assets/ui/modal/close.svg" alt="close" />
        </CloseBtn>
        {filterScreen === "start" && (
          <div>
            <LINE width={75} height={75} />
            {props.contents !== "" && <Message>{props.contents}</Message>}
          </div>
        )}
        {filterScreen === "filter" && (
          <SelectArea
            selectedLabels={selectedLabels}
            firstLabels={firstLabels}
            secondLabels={secondLabels}
            thirdLabels={thirdLabels}
            onFilter={setSelectedLabels}
          />
        )}
        {filterScreen === "result" && (
          <>
            <Button
              styling="border"
              text="絞り込み条件の変更"
              onClick={onFilter}
            />
            <Breadcrumb>
              絞り込み条件：
              {isLabelEmpty
                ? "なし"
                : `${selectedLabels.label1} > ${selectedLabels.label2} > ${selectedLabels.label3}`}
            </Breadcrumb>
            {filteredLineFriendsList.length !== 0 && (
              <FilterResult
                lineFriendList={filteredLineFriendsList}
                onChange={handleChange}
                selectedValue={selectedLINEFriendID}
                isModal={true}
              />
            )}
            {filteredLineFriendsList.length === 0 && (
              <div>検索結果がありません</div>
            )}
          </>
        )}
        <ButtonLINEWrapper>
          <div key={`custom-button-`} style={{ marginBottom: "15px" }}>
            {filterScreen === "start" && (
              <Button key="" text={buttonText} onClick={() => onNext()} />
            )}
            {filterScreen !== "start" && (
              <Button
                key=""
                text={buttonText}
                onClick={() => onNext()}
                disabled={filterScreen === "result" && !selectedLINEFriendID}
              />
            )}
          </div>
          {subButtonText && !lineSettings.is_line_friend_required && (
            <Button
              text={subButtonText}
              styling={"border"}
              color={"#000000"}
              onClick={() =>
                filterScreen === "result"
                  ? onWithoutLINENext()
                  : props.onClose()
              }
            />
          )}
        </ButtonLINEWrapper>
      </Container>
    </div>
  );
};
