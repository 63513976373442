import { axiosInstance, memberApi } from "./openAPIClient";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE}`;
const SUPAY_API_BASE_URL = `${process.env.REACT_APP_SUPAY_API_BASE}`;
const SUPAY_API_CLIENT_ID = `${process.env.REACT_APP_SUPAY_CLIENT_ID}`;

export const getSetting = async (tenant_code: string) => {
  return await axiosInstance.request({
    method: "get",
    baseURL: API_BASE_URL,
    url: `/media/tenant/${tenant_code}/tenant_info.json`,
    headers: {
      Authorization: undefined,
    },
    useAuth: false,
  });
};

/**
 * SU-PAYのアクセストークン取得
 */
export const getSupayToken = async () => {
  return await axiosInstance.request({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    baseURL: SUPAY_API_BASE_URL,
    url: "/crm/access",
    data: {
      clientId: SUPAY_API_CLIENT_ID,
    },
  });
};

/**
 * SU-PAYのカードIDの有効性チェック
 * @param cardId SU-PAYのカードID
 */
export const getSupayCardStatus = async (cardId: string, token: string) => {
  return await axiosInstance.request({
    method: "post",
    useAuth: false,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    baseURL: SUPAY_API_BASE_URL,
    url: "/crm/card-status",
    data: {
      cardID: cardId,
    },
  });
};

/**
 * SU-PAY(Auth)のアクセストークン取得
 * @param code SU-PAYの認証コード
 */
export const getSupayAuthToken = async (params: any, tenant_code: string) => {
  return memberApi.oauthConnectApiV1TenantCodeMembersOauthMemgerCodePost(
    tenant_code ?? "",
    {
      tenant_code,
      code: params.code,
      code_verifier: params.code_verifier,
      redirect_uri: params.redirect_uri,
    },
  );
};

export const getInformation = async (tenant_code: string) => {
  return await axiosInstance.request({
    method: "get",
    baseURL: API_BASE_URL,
    url: `/media/tenant/${tenant_code}/information_list.json`,
    headers: {
      Authorization: undefined,
    },
    useAuth: false,
  });
};

export const getLineFriendsList = async (tenant_code: string) => {
  return await axiosInstance.request({
    method: "get",
    baseURL: API_BASE_URL,
    url: `/media/tenant/${tenant_code}/line_friends.json`,
    headers: {
      Authorization: undefined,
    },
    useAuth: false,
  });
};
