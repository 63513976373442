import {
  FormEvent,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useState,
} from "react";

export const useTextField = (
  type: HTMLInputTypeAttribute,
  onInput?: (value: string) => void,
) => {
  const [inputType, setInputType] = useState<HTMLInputTypeAttribute>(type);
  const [showError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleOnInput = useCallback(
    (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShowError(true);

      if (onInput) onInput(e.currentTarget.value);
    },
    [],
  );

  const handleToggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  useEffect(() => {
    if (type !== "password") return;

    if (showPassword) setInputType("text");
    else setInputType("password");
  }, [showPassword]);

  return {
    inputType,
    showError,
    handleOnInput,
    showPassword,
    setShowPassword,
    handleToggleShowPassword,
  };
};
