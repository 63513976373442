import {
  CLEAR_LINE_FRIENDS_DATA,
  FETCH_LINE_FRIENDS_DATA_FAILURE,
  FETCH_LINE_FRIENDS_DATA_START,
  FETCH_LINE_FRIENDS_DATA_SUCCESS,
  FILTER_LINE_FRIENDS_LIST_DATA,
  SET_FILTER_LABELS_FIRST,
  SET_FILTER_LABELS_SECOND,
  SET_FILTER_LABELS_THIRD,
} from "./actions";
import { LineFriendsStore } from "./types";

export const initialState: LineFriendsStore = {
  loading: false,
  error: null,
  lineFriendsList: [],
  filteredLineFriendsList: [],
  firstLabels: [],
  secondLabels: [],
  thirdLabels: [],
};

export const reducer = (
  state = initialState,
  action: any,
): LineFriendsStore => {
  switch (action.type) {
    case FETCH_LINE_FRIENDS_DATA_START:
      return { ...state, loading: true, error: null };
    case FETCH_LINE_FRIENDS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        lineFriendsList: action.payload.line_friends,
      };
    case FETCH_LINE_FRIENDS_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FILTER_LINE_FRIENDS_LIST_DATA:
      return { ...state, filteredLineFriendsList: action.payload };
    case SET_FILTER_LABELS_FIRST:
      return { ...state, firstLabels: action.payload };
    case SET_FILTER_LABELS_SECOND:
      return { ...state, secondLabels: action.payload };
    case SET_FILTER_LABELS_THIRD:
      return { ...state, thirdLabels: action.payload };
    case CLEAR_LINE_FRIENDS_DATA:
      return initialState;
    default:
      return state;
  }
};
