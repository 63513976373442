import lotie from "lottie-web";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAssetStore from "../../../../lib/asset/redux/store";
import { addDisplayReward } from "../../../../lib/modalReward/redux/actions";
import useModalRewardStore from "../../../../lib/modalReward/redux/store";
import { Button } from "../../button/Button";
import { ModalState } from "../store/";
import {
  AnimationBg,
  Bg,
  CloseBtn,
  IconCheck,
  Image,
  ImageContainer,
  Message,
  ModalRewardContainer,
  RedrawContainer,
  SubTitle,
  Title,
} from "../style/style";

type ModalRewardProps = {
  rewardArray: ModalState[];
};

export const ModalReward: React.FC<ModalRewardProps> = (props) => {
  // state
  const [rewards, setRewards] = useState<ModalState[]>(props.rewardArray);
  const [reward, setReward] = useState<ModalState | undefined>(undefined);
  const dispatch = useModalRewardStore.getState().dispatch;
  const { tenant_code } = useParams();
  const user_id = useAssetStore((state) => state.member.id);
  const [initFlg, setInitFlg] = useState<boolean>(false);

  useEffect(() => {
    if (!initFlg && props.rewardArray.length > 0) {
      setRewards(props.rewardArray);
      const reward = props.rewardArray ? props.rewardArray[0] : undefined;
      setReward(reward);
      setInitFlg(true);
    }
  }, [props.rewardArray]);

  useEffect(() => {
    if (reward && reward.reward_id && user_id && tenant_code) {
      dispatch(addDisplayReward(reward.reward_id, user_id, tenant_code));
    }
  }, [reward, dispatch, user_id, tenant_code]);

  const onLoadImage = useCallback((className: string) => {
    const target = document
      .getElementsByClassName(className)
      .item(0) as Element;
    const anim = lotie.loadAnimation({
      container: target,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "/assets/animation/redraw/data.json",
    });
    anim.play();
  }, []);

  const closeReward = useCallback(() => {
    const nextRewards = rewards.slice(1);
    setRewards(nextRewards);
    setReward(nextRewards[0]);
  }, [rewards]);

  const close = useCallback(() => {
    setReward(undefined);
    setTimeout(() => {
      closeReward();
    }, 250);
  }, [closeReward]);

  if (!reward) {
    return null;
  }

  return (
    <div>
      <Bg isOpen={true} />
      {reward && (
        <ModalRewardContainer isOpen={reward.isShow ?? false}>
          <div>
            <SubTitle>{reward.subTitle}</SubTitle>
            <Title>{reward.title}</Title>
            <CloseBtn
              onClick={() => {
                close();
              }}
            >
              <img src="/assets/ui/modal/close.svg" alt="close" />
            </CloseBtn>

            {reward.image_url && (
              <RedrawContainer>
                <AnimationBg className={`redraw_anim`}></AnimationBg>
                <ImageContainer>
                  <Image
                    onLoad={() => {
                      onLoadImage(`redraw_anim`);
                    }}
                    src={reward.image_url ?? ""}
                    alt="sample"
                    width="100%"
                    height="auto"
                  />
                </ImageContainer>
                <IconCheck src="/assets/ui/modal/icon_check.svg" />
                <Message useAnim={reward.useAnimation} padding="0">
                  {reward.contents}
                </Message>
              </RedrawContainer>
            )}
            <Button
              text={"閉じる"}
              styling={"border"}
              color={"#000000"}
              onClick={() => close()}
            />
          </div>
        </ModalRewardContainer>
      )}
    </div>
  );
};
