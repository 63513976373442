import { useCallback, useState } from "react";
import { SelectionOption } from "../types";

export const useSelection = (
  placeholder: string,
  onSelection?: (value: string) => void,
) => {
  const [selection, setSelection] = useState<string>(placeholder);
  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const handleOnClick = useCallback(() => {
    setShowError(true);
    setShowSelection((prev) => !prev);
  }, []);

  const handleOnSelection = useCallback((option: SelectionOption) => {
    setShowError(true);
    setSelection(option.name);

    if (onSelection) onSelection(option.value);
  }, []);

  const truncateString = (str: string, maxLength = 20): string => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  return {
    selection,
    showError,
    showSelection,
    handleOnClick,
    handleOnSelection,
    truncateString,
  };
};
