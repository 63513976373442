import { RadioGroup } from "@mui/material";
import React from "react";
import { LineFriends } from "../../../../lib/lineFriends/redux/types";
import { RadioButton } from "../../../ui/radio/RadioButton";
import { SortResultArea } from "../style/select-area";

type Props = {
  lineFriendList: LineFriends[];
  selectedValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isModal?: boolean;
};

const customStyle = {
  borderBottom: "1px solid #E0E0E0",
  marginRight: "0",
};

export const FilterResult: React.FC<Props> = ({
  lineFriendList,
  selectedValue,
  onChange,
  isModal,
}) => {
  return (
    <SortResultArea isModal={isModal}>
      <RadioGroup>
        {lineFriendList.length !== 0 &&
          lineFriendList.map((lineAccountInfo: LineFriends, index: number) => (
            <RadioButton
              key={index}
              label={lineAccountInfo.line_account_name}
              value={lineAccountInfo.id.toString()}
              selectedValue={selectedValue}
              onChange={(val) => onChange(val)}
              customStyle={customStyle}
            />
          ))}
      </RadioGroup>
    </SortResultArea>
  );
};
