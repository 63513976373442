import React from "react";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import {
  EncourageRegisterMemberImage,
  EncourageRegisterMemberWrapper,
} from "../style/connect-member";
import { ConnectMemberButton } from "../ui/ConnectMemberButton";

export const EncourageRegisterMember: React.FC = () => {
  const navigate = useNavigate();
  const onGoToLogin = () => {
    navigate("/login");
  };

  return (
    <EncourageRegisterMemberWrapper onClick={onGoToLogin}>
      <EncourageRegisterMemberImage src="/assets/campaign/register_area_image.svg" />
      <ConnectMemberButton text="ログイン・会員登録" onClick={onGoToLogin} />
    </EncourageRegisterMemberWrapper>
  );
};
