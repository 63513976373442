import React, { useCallback } from "react";
import { BtnContainer, HeaderContainer } from "../../../../common/style";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { RegisterMemberStepThreeContent } from "../../../template/style";
import { TextField } from "../../../ui";
import { Selection } from "../../../ui/selection/Selection";
import {
  GENDER_SELECTION_OPTIONS,
  MEMBER_REGISTRATION_STEP_IMAGES_PATH,
  MONTH_SELECTION_OPTIONS,
  YEAR_SELECTION_OPTIONS,
} from "../constant";
import useMemberRegistrationStore from "../redux/store";
import { Container, StepImage, SubTitle } from "../style";

export const MemberRegistrationStepThreeContainer: React.FC = () => {
  const email = useMemberRegistrationStore((state) => state.email);
  const gender = useMemberRegistrationStore((state) => state.gender);
  const year = useMemberRegistrationStore((state) => state.year);
  const month = useMemberRegistrationStore((state) => state.month);
  const membershipNumber = useMemberRegistrationStore(
    (state) => state.membershipNumber,
  );
  const navigation = useNavigate();

  const onPrev = useCallback(() => {
    navigation("/register/2");
  }, []);

  return (
    <RegisterMemberStepThreeContent>
      <HeaderContainer>
        <BtnContainer onClick={onPrev}>
          <img src="/assets/common/icon_back.svg" />
        </BtnContainer>
        <h3>新規会員登録</h3>
      </HeaderContainer>

      <StepImage
        src={MEMBER_REGISTRATION_STEP_IMAGES_PATH.replace("{step}", "3")}
      />

      <SubTitle>入力内容を確認してください</SubTitle>

      <Container>
        <TextField
          type="email"
          name="email"
          label="メールアドレス"
          disabled
          value={email}
        />

        <Selection
          name="gender"
          label="性別"
          required
          options={GENDER_SELECTION_OPTIONS}
          disabled
          value={gender ? gender.toString() : undefined}
        />

        <Selection
          name="year"
          label="生まれ年"
          required
          options={YEAR_SELECTION_OPTIONS}
          disabled
          value={year ? year.toString() : undefined}
        />

        <Selection
          name="month"
          label="誕生月"
          required
          options={MONTH_SELECTION_OPTIONS}
          disabled
          value={month ? month.toString() : undefined}
        />

        <TextField
          name="membership-number"
          label="会員番号"
          required
          disabled
          value={membershipNumber}
        />
      </Container>
    </RegisterMemberStepThreeContent>
  );
};
