import styled from "styled-components";

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-shrink: 0;
  padding: 20px 0;
  box-shadow: 0px 0px 10px 0px #d3d3d3;
  background-color: #ffffff;
  z-index: 1001;
`;

export const AreementWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const FooterAgreeText = styled.div`
  font-size: 16px;
  color: #999999;
`;

export const FooterAgreeSubText = styled.div`
  font-size: 12px;
  color: #cc7673;
  margin: 10px 0;
`;

export const FooterSecondaryButton = styled.div`
  margin-bottom: 8px;
`;

export const LineFooterSecondaryButton = styled.div`
  margin-top: 10px;
`;
