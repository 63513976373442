import create from "zustand";
import { persist, redux } from "zustand/middleware";
import {
  excludeCategories,
  filterAndSortPromotedCampaigns,
  filterWithinEventPeriodCampaigns,
} from "../../../../../common/utils/campaign";
import { campaignApi } from "../../../../../core/http/openAPIClient";
import useAssetStore from "../../../../../lib/asset/redux/store";
import { MemberCampaign } from "../../../../../lib/asset/redux/types";
import { log } from "../../../../../lib/hooks/useLogMiddleware";
import { Campaign, Reward } from "../../campaign.type";
import {
  fetchCampaignFailure,
  fetchCampaignStart,
  fetchCampaignSuccess,
  fetchCategoryData,
  filterCampaignData,
  filterPromotedCampaignData,
  joinCampaignFailure,
  joinCampaignSuccess,
} from "./actions";
import { initialState, reducer } from "./reducer";

const useCampaignStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "campaigns",
    }),
  ),
);

export const fetchCampaigns = async (tenant_code: string) => {
  const dispatch = useCampaignStore.getState().dispatch;
  const memberCampaigns = useAssetStore.getState().memberCampaigns;
  const memberRewards = useAssetStore.getState().memberRewards;
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  dispatch(fetchCampaignStart());
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GCS_BASE}media/tenant/${tenant_code}/campaign_list.json`,
    );
    const jsonData = await response.json();
    const campaigns = jsonData.campaign_list;
    // 開催期間内のキャンペーンのみを抽出
    const filteredCampaigns = filterWithinEventPeriodCampaigns(campaigns);
    let formattedCampaigns: Campaign[] = filteredCampaigns;
    // ログインしている場合、参加済みキャンペーンの情報を付与
    if (isLogin) {
      const addedParticipatingCampaigns = filteredCampaigns.map(
        (campaign: Campaign) => {
          const matchedCampaign = memberCampaigns.find(
            (c: MemberCampaign) => c.campaign_id === campaign.id,
          );
          const matchedRewards = memberRewards.filter((reward: Reward) => {
            return reward.campaign_id === campaign.id;
          });
          if (
            memberCampaigns.some(
              (c: MemberCampaign) => c.campaign_id === campaign.id,
            )
          ) {
            return {
              ...campaign,
              participating: true,
              accumulated_mileage: matchedCampaign.accumulated_mileage,
              before_campaign_reward_id:
                matchedCampaign.before_campaign_reward_id,
              before_mileage: matchedCampaign.before_mileage,
              next_campaign_reward_id: matchedCampaign.next_campaign_reward_id,
              next_mileage: matchedCampaign.next_mileage,
              purchase_logs: matchedCampaign.purchase_logs,
              matchedRewards: matchedRewards,
            };
          }
          return campaign;
        },
      );
      formattedCampaigns = addedParticipatingCampaigns;
    }
    formattedCampaigns.sort((a: Campaign, b: Campaign) => {
      return (
        new Date(b.publication_period_start).getTime() -
        new Date(a.publication_period_start).getTime()
      );
    });
    const excludedPromotedCampaigns = formattedCampaigns.filter(
      (campaign: Campaign) => {
        return !campaign.is_promoted;
      },
    );
    // カテゴリの抽出
    const categories = excludeCategories(formattedCampaigns);
    dispatch(fetchCategoryData(categories));
    dispatch(fetchCampaignSuccess(formattedCampaigns));
    dispatch(filterCampaignData(excludedPromotedCampaigns));
  } catch (error) {
    console.error("fetch campaign list error:", error);
    dispatch(fetchCampaignFailure(error));
  }
};

export const filterCampaigns = (category: string) => {
  const state = useCampaignStore.getState();
  const excludedPromotedCampaigns = state.campaigns.filter(
    (campaign: Campaign) => {
      return !campaign.is_promoted;
    },
  );
  const promotedCampaigns = filterAndSortPromotedCampaigns(state.campaigns);
  if (category === "all") {
    state.dispatch(filterCampaignData(excludedPromotedCampaigns));
    state.dispatch(filterPromotedCampaignData(promotedCampaigns));
    return;
  }
  const filteredCampaigns = excludedPromotedCampaigns.filter(
    (campaign: Campaign) => {
      return campaign.campaign_categories.includes(category);
    },
  );
  const filteredPromotedCampaignsByCategory = promotedCampaigns.filter(
    (campaign: Campaign) => {
      return campaign.campaign_categories.includes(category);
    },
  );
  state.dispatch(filterCampaignData(filteredCampaigns));
  state.dispatch(
    filterPromotedCampaignData(filteredPromotedCampaignsByCategory),
  );
};

export const filterPromotedCampaigns = async () => {
  const state = useCampaignStore.getState();
  const promotedCampaigns = filterAndSortPromotedCampaigns(state.campaigns);
  state.dispatch(filterPromotedCampaignData(promotedCampaigns));
};

export const resetCampaignData = () => {
  const state = useCampaignStore.getState();
  state.dispatch(filterCampaignData(state.campaigns));
};

export const joinCampaign = async (
  campaignId: number,
  tenant_code: string,
  onSuccess: (response: any) => void,
): Promise<any> => {
  const state = useCampaignStore.getState();
  await campaignApi
    .campaignEntryApiV1TenantCodeCampaignEntryPost(tenant_code, {
      campaign_id: campaignId,
    })
    .then((res) => {
      state.dispatch(joinCampaignSuccess(res.data.result));
      onSuccess(res);
    })
    .catch((error) => {
      console.error("join campaign error", error);
      state.dispatch(joinCampaignFailure(error));
    });
};

export const updateLoading = (loading: boolean) => {
  const state = useCampaignStore.getState();
  state.loading = loading;
};

export default useCampaignStore;
