import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchAssets } from "../../../lib/asset/redux/store";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { ResponseType } from "../../../lib/index.type";
import {
  APP_LINKAGE_SUBTEXT,
  CAMERA_CAPTURE_SUBTEXT,
  ERROR_CODE_MEMBER_ID,
  MEMBERSHIP_NUMBER_VALIDATION_TEXT,
} from "../../features/member_registration/constant";
import { useMemberRegister } from "../../features/member_registration/hooks/useMemberRegister";
import useMemberRegistrationStore, {
  setMemberRegistrationMembershipNumber,
  updateMemberInfomation,
} from "../../features/member_registration/redux/store";
import {
  Bg,
  ButtonReturn,
  Header,
  ImageContainer,
  Lead,
  MainImage,
  OCRContainer,
  SubTextArea,
} from "../../features/member_registration/style";
import { OCR } from "../../features/ocr";
import { HeaderTemplate } from "../../template";
import { settingStore } from "../../template/store/setting";
import { Base, MemberContent } from "../../template/style";
import { Button, Modal, TextField } from "../../ui";
import { Footer } from "../../ui/footer/Footer";
import { ErrorMessage } from "../../ui/textfield/style";

export const ChangeRegisterdNumber: React.FC = () => {
  const navigate = useNavigate();
  const [showOCR, setShowOCR] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { tenant_code } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const member_id = query.get("member_id");
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const membershipNumber = useMemberRegistrationStore(
    (state) => state.membershipNumber,
  );
  const member_code_confirm = settingStore(
    (state) => state.member_code_confirm,
  );
  const enabled = useMemberRegistrationStore((state) => state.enabled);
  const retailIdInput = settingStore((state) => state.retail_id_input);
  const { setAppLinkage, getSupayId } = useMemberRegister();
  const [isMemberIdError, setIsMemberIdError] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const onBack = useCallback(() => {
    setIsShowModal(false);
    // if (!permissionError) naviagete("/menu");
    setPermissionError(false);
  }, [permissionError]);

  const onEditRegisterNumber = useCallback(() => {
    if (tenant_code) {
      updateMemberInfomation(tenant_code)
        .then((res) => {
          if ((res as ResponseType)?.error) {
            setIsError(true);
            setIsShowModal(true);
          } else {
            setIsError(false);
            setIsShowModal(true);
            if (isLogin) {
              fetchAssets(tenant_code || "");
            }
          }
        })
        .catch((e) => {
          setIsError(true);
          setIsShowModal(true);
          setIsMemberIdError(
            ERROR_CODE_MEMBER_ID.includes(
              e.payload.error.response.data["error-code"],
            ),
          );
        });
    } else {
      setIsError(true);
      setIsShowModal(true);
    }
  }, [tenant_code]);

  const onError = (e: string | DOMException) => {
    if (e) {
      setPermissionError(true);
      setIsShowModal(true);
    }
  };

  const openImageToOtherTab = () => {
    navigate("/member_code_confirm_image");
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (member_id && isLoaded) {
      window.scroll({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [isLoaded]);

  return (
    <Base>
      <HeaderTemplate>
        <MemberContent>
          <Bg isOpen={showOCR} />
          <Header>
            <ButtonReturn
              onClick={() => {
                history.back();
              }}
            >
              <img src="/assets/common/icon_back.svg" />
            </ButtonReturn>
            登録した会員番号の変更
          </Header>
          <Lead>{member_code_confirm.expository_text}</Lead>
          <ImageContainer>
            <MainImage
              src={member_code_confirm.image_url}
              onLoad={handleImageLoad}
              onClick={openImageToOtherTab}
            />
          </ImageContainer>
          {retailIdInput.enable_using_camera && (
            <>
              <Button
                text={retailIdInput.using_camera_button_name}
                styling="border"
                color="#000000"
                onClick={() => setShowOCR(true)}
                containerStyle={{ margin: "20px 0 12px 0" }}
              />
              <SubTextArea>{CAMERA_CAPTURE_SUBTEXT}</SubTextArea>
            </>
          )}
          {retailIdInput.enable_enter_from_app && (
            <>
              <Button
                onClick={() => setAppLinkage("/change_register_number")}
                text={
                  retailIdInput.enter_from_app_button_name +
                  (member_id ? "（連携済み）" : "")
                }
                styling="border"
                color="#000000"
                containerStyle={{ margin: "20px 0 12px 0" }}
              />
              <SubTextArea>{APP_LINKAGE_SUBTEXT}</SubTextArea>
            </>
          )}
          <div style={{ margin: "10px" }}>
            {isMemberIdError && (
              <ErrorMessage>入力された会員番号が不正です</ErrorMessage>
            )}
            <TextField
              name="membership-number"
              label="会員番号"
              required
              placeholder={MEMBERSHIP_NUMBER_VALIDATION_TEXT}
              onInput={setMemberRegistrationMembershipNumber}
              value={membershipNumber}
            />
          </div>

          {showOCR && (
            <OCRContainer>
              <OCR
                onCapture={(text) =>
                  setMemberRegistrationMembershipNumber(text)
                }
                onClose={() => setShowOCR(false)}
                onError={(e) => onError(e)}
              />
            </OCRContainer>
          )}
        </MemberContent>
        <Modal
          isShow={isShowModal}
          title={
            permissionError
              ? "ブラウザの設定の、<br>カメラの使用を許可してください"
              : isError
                ? `会員IDを更新できませんでした。`
                : `会員IDを更新しました。`
          }
          subTitle=""
          contents=""
          onClose={onBack}
        />
      </HeaderTemplate>
      <Footer
        buttonText="保存"
        onClickButton={onEditRegisterNumber}
        buttonDisabled={!enabled}
      />
    </Base>
  );
};
