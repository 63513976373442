import React from "react";
import { useParams } from "react-router-dom";
import { AppConfig } from "../../../../AppConfig";
import { LINE_ID_COLOR } from "../../../../common/constant/colors";
import {
  EMAIL_VALIDATION_TEXT,
  PASSWORD_VALIDATION_TEXT,
} from "../../../../common/constant/validations";
import { LocalStorageManager } from "../../../../core/storage/LocalStorageManager";
import { useLineLink } from "../../../../lib/hooks/useLineLink";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { concatInformation } from "../../../../lib/information/redux/store";
import { LoginType, settingStore } from "../../../template/store/setting";
import { Button, TextButton, TextField } from "../../../ui";
import useLoginStore, {
  onLogin,
  setLoginEmail,
  setLoginPassword,
} from "../redux/store";
import {
  BtnContainer,
  Container,
  HeaderContainer,
  LineBtnContainer,
  OptionContainer,
  TextContainer,
  Title,
} from "../style";
import "../style/index.css";

export const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const tenant_code = useParams().tenant_code;

  const email = useLoginStore((state) => state.email);
  const emailError = useLoginStore((state) => state.emailError);
  const password = useLoginStore((state) => state.password);
  const passwordError = useLoginStore((state) => state.passwordError);
  const enabled = useLoginStore((state) => state.enabled);
  const login_type: LoginType = settingStore((state) => state.login_type);

  const { showLineLink } = useLineLink("login");

  return (
    <div>
      <HeaderContainer>
        <Title>ログイン</Title>
      </HeaderContainer>

      {(login_type === "email" || login_type === "email_line") && (
        <>
          <Container>
            <TextField
              type="email"
              name="email"
              placeholder={EMAIL_VALIDATION_TEXT}
              onInput={setLoginEmail}
              value={email}
              error={emailError}
            />
            <TextField
              type="password"
              name="password"
              placeholder={PASSWORD_VALIDATION_TEXT}
              onInput={setLoginPassword}
              value={password}
              error={passwordError}
            />
          </Container>

          <BtnContainer>
            <Button
              text="ログイン"
              disabled={!enabled}
              onClick={() => {
                if (tenant_code) {
                  onLogin(tenant_code, (res) => {
                    LocalStorageManager.Instance.saveObject(
                      AppConfig.Instance.LocalStorageKey["accessToken"],
                      res.data.access_token,
                    );
                    concatInformation(tenant_code || "");
                    navigate("/campaign");
                  });
                } else {
                  alert("登録できませんでした");
                }
              }}
            />
          </BtnContainer>
        </>
      )}

      {login_type === "email_line" && <OptionContainer>または</OptionContainer>}

      {(login_type === "line" || login_type === "email_line") && (
        <LineBtnContainer>
          <Button
            text="LINE IDでログイン"
            color={LINE_ID_COLOR}
            onClick={showLineLink}
          />
        </LineBtnContainer>
      )}
      <Container>
        <TextButton
          text="パスワードを忘れた方はこちら"
          onClick={() => navigate("/forgot_password")}
        />
      </Container>
      <TextContainer className={"register_header"}>
        <p>初めてご利用する方はこちら</p>
      </TextContainer>
      <BtnContainer>
        <Button
          text="新規会員登録（3分で完了）"
          styling="border"
          color="#000000"
          onClick={() => navigate("/terms_of_service")}
        />
      </BtnContainer>
    </div>
  );
};
