import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styled from "styled-components";

type CustomProps = {
  option?: string;
  selected_option?: string;
  checked?: boolean;
  isLogin?: boolean;
  isPromoted?: boolean;
  isDouble?: boolean;
};

export const SelectTab = styled(RadioGroup)<CustomProps>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  border-bottom: 1px solid #f5f5f5;
`;

export const TabRadioInput = styled(Radio)`
  display: none !important;
`;

export const TabLabel = styled(FormControlLabel)<CustomProps>`
  background: #ffffff;
  white-space: nowrap;
  text-align: center;
  padding: 10px 0.5em;
  order: -1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-left: 0px !important; /* デフォルトのマージンをリセット */
  margin-right: 0px !important; /* デフォルトのマージンをリセット */

  ${(props) => {
    if (props.checked) {
      return `
      border-bottom: 2px solid ${props.color || "#0271EF"};
      `;
    }
  }}
  /* label部分 */
  .MuiFormControlLabel-label {
    font-size: 13px;
    color: #000000;
    ${(props) => {
      if (props.checked) {
        return `
        font-weight: bold;
        color: ${props.color || "#0271EF"};
        `;
      }
    }}
  }
`;

export const FilterButtonWrap = styled.div`
  position: sticky;
  top: 40px;
  display: flex;
  padding: 12px 16px;
  z-index: 99;
  background-color: #ffffff;
  overflow-x: auto;
`;

export const FilterButton = styled.button<CustomProps>`
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  margin-right: 5px;
  border: 1px solid #c3c3c3;
  border-radius: 16px;
  padding: 2px 14px 3px 14px;
  font-size: 12px;
  font-weight: medium;
  min-width: auto;
  white-space: nowrap;
  ${(props) => {
    if (props.option === props.selected_option) {
      return `
        color:  ${props.color ? props.color : "#0271EF"};
        border: 1px solid  ${props.color ? props.color : "#0271EF"};
        font-weight: bold;
        background-color: #EAF3FF;
      `;
    }
  }}
`;

export const CampaignCardContainer = styled.div<CustomProps>`
  ${(props) => {
    if (props.isPromoted === true) {
      return `
      background-color: #FFBABA;
      padding: 10px 0;

    `;
    }
  }}
`;

export const CampaignPRTitle = styled.div`
  font-size: 14px;
  color: #000000;
  width: 100%;
  background-color: #ffffff;

  padding: 2px 0;
  border-radius: 50px;
  font-weight: bold;
`;

export const CampaignPRTitleWrapper = styled.div`
  padding: 13px 20px 3px 20px;

  background-color: #ffbaba;
`;

export const CampaignCard = styled.div<CustomProps>`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 0 auto;

  margin: 20px 5%;

  ${(props) => {
    if (props.isPromoted === true) {
      return `
        margin: 0  5%;

    `;
    }
  }}
  font-family: Arial, sans-serif;
  text-align: center;
`;

export const CampaignCardImage = styled.img`
  width: 100%;
  height: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
export const CampaignButtonContainer = styled.div<CustomProps>`
  ${(props) => {
    if (props.isLogin === true) {
      return `
        display: flex;
    `;
    }
  }}
`;
export const CampaignJoinButton = styled.button<CustomProps>`
  background-color: ${(props) => (props.color ? props.color : "#10459b")};
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid ${(props) => (props.color ? props.color : "#10459b")};
  border-radius: 8px;
  padding: 4px 16px;
  margin: 10px 16px 16px 16px;
  cursor: pointer;
  min-height: 32px;
  width: 68%;
`;
export const CampaignDetailLink = styled.button<CustomProps>`
  background-color: #ffffff;
  color: black;
  font-size: 14px;
  font-weight: medium;
  border: 1px solid black;
  border-radius: 8px;
  margin: 10px 16px 16px 16px;
  min-height: 32px;

  flex: 1;
  cursor: pointer;
  ${(props) => {
    if (props.isLogin) {
      return `
        margin-left: 0px
      `;
    }
    if (props.isLogin === false) {
      return `
        width: 90%;
    `;
    }
  }}
  ${(props) => {
    if (!props.isDouble) {
      return `
        margin-left: 0px
      `;
    }
  }}
`;

export const CampaignCardText = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

export const BackGround = styled.div`
  background-color: #efefef;
  padding: 1px 0;
  min-height: calc(100% - 92px);
  height: auto;
`;

export const BaseText = styled.p`
  font-size: 16px;
  padding: 0px 20px 20px 20px;
`;

export const CenteredText = styled.div`
  margin: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: pre-wrap;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #999999;
`;

export const VerticalDivider = styled.div`
  height: 23.5px;
  width: 1px;
  margin-right: 10px;
  margin-left: 5px;
  background-color: #c3c3c3;
`;

export const CampaignCardWrapper = styled.div`
  height: 100%;
`;
