import React from "react";
import { Selection } from "../../../ui/selection/Selection";
import { SelectionArea } from "../style/select-area";
import { Labels } from "../types";

type SelectionOption = {
  name: string;
  value: string;
};

type Props = {
  selectedLabels: Labels;
  firstLabels: SelectionOption[];
  secondLabels: SelectionOption[];
  thirdLabels: SelectionOption[];
  onFilter: (updateLabels: (prevLabels: Labels) => Labels) => void;
};

export const SelectArea: React.FC<Props> = ({
  selectedLabels,
  onFilter,
  firstLabels,
  secondLabels,
  thirdLabels,
}) => {
  const updateLabel = (key: keyof Labels, value: string) => {
    onFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <SelectionArea>
      <Selection
        name="area"
        label="エリア"
        placeholder="エリアを選択"
        options={firstLabels}
        onSelection={(val: string) => {
          updateLabel("label1", val.toString());
        }}
        classes={{ width: "auto", margin: "5px auto" }}
        selectClasses={{ margin: "8px 0" }}
        value={selectedLabels.label1 ? selectedLabels.label1 : undefined}
      />
      <Selection
        name="prefecture"
        label="都道府県"
        placeholder="都道府県を選択"
        options={secondLabels}
        onSelection={(val: string) => {
          updateLabel("label2", val.toString());
        }}
        classes={{ width: "auto", margin: "5px auto" }}
        selectClasses={{ margin: "8px 0" }}
        value={selectedLabels.label2 ? selectedLabels.label2 : undefined}
        noSelection={selectedLabels.label1 ? false : true}
      />
      <Selection
        name="district"
        label="地区"
        placeholder="地区を選択"
        options={thirdLabels}
        onSelection={(val: string) => {
          updateLabel("label3", val.toString());
        }}
        classes={{ width: "auto", margin: "5px auto" }}
        selectClasses={{ margin: "8px 0" }}
        value={selectedLabels.label3 ? selectedLabels.label3 : undefined}
        noSelection={selectedLabels.label2 ? false : true}
      />
    </SelectionArea>
  );
};
