import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

type ModalProps = {
  isOpen?: boolean;
};
export const Bg = styled.div<ModalProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

export const OCRContainer = styled.div`
  position: fixed;
  top: 15dvh;
  left: 0;
  right: 0;
  width: 93%;
  z-index: 100;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px;
  border: 1px solid #cccccc;
  animation: ${fadeIn} 0.25s linear;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  z-index: 10001;
`;
export const SubTitle = styled.p`
  margin: 30px 16px 16px 16px;
  font-size: 16px;
  text-align: left;
`;

export const Container = styled.div`
  margin: 20px 16px 16px 16px;
`;

export const TextContainer = styled.p`
  text-align: left;
  margin: 20px 16px 0 16px;
`;

export const ButtonContainer = styled.div`
  margin: 25px 6px 0 6px;
`;

export const PasswordChangeText = styled.p`
  text-align: left;
  margin: 16px 0 0 0;
  font-size: 15px;
  color: #333333;
  line-height: 1.7em;
`;
export const PasswordChangeSubText = styled.p`
  text-align: left;
  margin: 0 0 0 0;
  font-size: 14px;
  color: #666666;
  line-height: 1.7em;
`;
export const GeneratePasswordButton = styled.div`
  margin: 10px 0 10px 0;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.7em;
  border: 1px solid #000000;
  border-radius: 4px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:active {
    background-color: #d0d0d0;
    color: #ffffff;
  }
`;

export const SelectionContainer = styled.div`
  margin-top: 16px;
  item-align: center;
`;

export const StepImage = styled.img`
  width: 248px;
  height: 42px;
`;

export const GuideImage = styled.img`
  width: 92.5vw;
  max-width: 640px;
  height: "100%";
`;

export const Header = styled.h3`
  position: relative;
  margin: 30px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25em;
`;
export const Lead = styled.p`
  margin: 0 15px 12px 15px;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6em;
`;
export const ImageContainer = styled.div`
  margin: 0 15px 30px 15px;
`;
export const MainImage = styled.img`
  width: 100%;
  max-width: 640px;
  height: auto;
`;
export const SubTextArea = styled.p`
  margin: 0 15px 25px 15px;
  color: #999999;
  font-size: 14px;
  text-align: left;
  line-height: 1.6em;
`;
export const ButtonReturn = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  font-size: 0px;
  line-height: 1em;
  margin-left: 15px;
  cursor: pointer;
`;
