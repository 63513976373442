import React, { createContext, useEffect } from "react";
import { SettingState } from "../../components/template/store/setting";
import { fetchAssets } from "../../lib/asset/redux/store";
import { useSetting } from "../../lib/hooks/useSetting";
import { concatInformation } from "../../lib/information/redux/store";
import { fetchLineFriendsList } from "../../lib/lineFriends/redux/store";

export type BaseContextType = {
  setting: SettingState;
};
export const BaseContext = createContext<BaseContextType>(
  {} as BaseContextType,
);

type Props = {
  children: React.ReactNode;
};

export const BaseProvider = (props: Props) => {
  const { loadSetting, setting } = useSetting();
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const pathName = location.pathname;
  const dirAry = pathName.split("/");
  const tenant_code = dirAry[1];

  useEffect(() => {
    loadSetting();
    if (isLogin && tenant_code) {
      fetchAssets(tenant_code || "");
      concatInformation(tenant_code || "");
    }
    fetchLineFriendsList(tenant_code || "");
  }, []);

  const value: BaseContextType = {
    setting: setting,
  };

  return (
    <BaseContext.Provider value={value}>{props.children}</BaseContext.Provider>
  );
};
