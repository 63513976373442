export const FETCH_LINE_FRIENDS_DATA_START = "FETCH_LINE_FRIENDS_DATA_START";
export const FETCH_LINE_FRIENDS_DATA_SUCCESS =
  "FETCH_LINE_FRIENDS_DATA_SUCCESS";
export const FETCH_LINE_FRIENDS_DATA_FAILURE =
  "FETCH_LINE_FRIENDS_DATA_FAILURE";
export const FILTER_LINE_FRIENDS_LIST_DATA = "FILTER_LINE_FRIENDS_LIST_DATA";
export const SET_FILTER_LABELS_FIRST = "SET_FILTER_LABELS_FIRST";
export const SET_FILTER_LABELS_SECOND = "SET_FILTER_LABELS_SECOND";
export const SET_FILTER_LABELS_THIRD = "SET_FILTER_LABELS_THIRD";
export const CLEAR_LINE_FRIENDS_DATA = "CLEAR_LINE_FRIENDS_DATA";

export const fetchLineFriendsDataStart = () => ({
  type: FETCH_LINE_FRIENDS_DATA_START,
});
export const fetchLineFriendsDataSuccess = (data: any) => ({
  type: FETCH_LINE_FRIENDS_DATA_SUCCESS,
  payload: data,
});
export const fetchLineFriendsDataFailure = (error: any) => ({
  type: FETCH_LINE_FRIENDS_DATA_FAILURE,
  payload: error,
});
export const filterLineFriendsListData = (data: any) => ({
  type: FILTER_LINE_FRIENDS_LIST_DATA,
  payload: data,
});
export const setFilterLabelsFirst = (data: any) => ({
  type: SET_FILTER_LABELS_FIRST,
  payload: data,
});
export const setFilterLabelsSecond = (data: any) => ({
  type: SET_FILTER_LABELS_SECOND,
  payload: data,
});
export const setFilterLabelsThird = (data: any) => ({
  type: SET_FILTER_LABELS_THIRD,
  payload: data,
});
export const clearLineFriendsData = () => ({ type: CLEAR_LINE_FRIENDS_DATA });
