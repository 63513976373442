import React from "react";
import { ScrollRestoration, useSearchParams } from "react-router-dom";
import { PAGE_TYPE, PREVIEW } from "../../features/campaign/constant";
import {
  CampaignDetailContainer,
  CampaignParticipatingDetailContainer,
} from "../../features/campaign_detail";
import { CampaignDetailPreviewContainer } from "../../features/campaign_detail/container/CampaignDetailPreviewContainer";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";

export const CampaignDetail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  let component;
  switch (tab) {
    case PAGE_TYPE.PROGRESS:
      component = <CampaignDetailContainer />;
      break;
    case PAGE_TYPE.PARTICIPATING:
      component = <CampaignParticipatingDetailContainer />;
      break;
    case PREVIEW:
      component = <CampaignDetailPreviewContainer />;
      break;
    default:
      component = <CampaignDetailContainer />;
      break;
  }
  return (
    <Base>
      <HeaderTemplate preview={tab === PREVIEW ? true : false}>
        {component}
      </HeaderTemplate>
      <ScrollRestoration />
    </Base>
  );
};
