import React from "react";
import { Button } from "../button/Button";
import { TextButton } from "../button/TextButton";
import { FooterContainer, LineFooterSecondaryButton } from "./style";

type Props = {
  onClickButton: () => void;
  buttonText?: string;
  buttonDisabled?: boolean;
  secondaryButtonText?: string;
  filterScreen: string;
  onClickSecondaryButton?: () => void;
  textButtonText?: string;
  onClickTextButton?: () => void;
  selectedValue?: string;
  isRequiredLineFriend?: boolean;
};

export const LineFooter: React.FC<Props> = ({
  buttonText = "次へ",
  buttonDisabled = false,
  onClickButton,
  secondaryButtonText,
  filterScreen,
  onClickSecondaryButton,
  textButtonText,
  onClickTextButton,
  isRequiredLineFriend,
}) => {
  return (
    <FooterContainer>
      <Button
        text={buttonText}
        onClick={onClickButton}
        disabled={buttonDisabled}
      />
      {secondaryButtonText && !isRequiredLineFriend && (
        <LineFooterSecondaryButton>
          <Button
            styling="border"
            color="#000000"
            text={secondaryButtonText}
            onClick={onClickSecondaryButton}
          />
        </LineFooterSecondaryButton>
      )}
      {textButtonText && (
        <TextButton text={textButtonText} onClick={onClickTextButton} />
      )}
    </FooterContainer>
  );
};
