import {
  Campaign,
  CampaignReward,
  Reward,
} from "../../components/features/campaign/campaign.type";
import {
  fetchCampaigns,
  filterPromotedCampaigns,
  updateLoading,
} from "../../components/features/campaign/redux/campaign/store";
import {
  fetchEarnedCampaigns,
  filterEarnedCampaigns,
  resetEarnedCampaignData,
} from "../../components/features/campaign/redux/earnedCampaign/store";
import { fetchParticipatingCampaigns } from "../../components/features/campaign/redux/participatingCampaign/store";
import { fetchAssets } from "../../lib/asset/redux/store";

export const fetchCampaignData = async (
  tenant_code: string,
  isLogin: boolean,
) => {
  updateLoading(true);
  if (isLogin) {
    await fetchAssets(tenant_code || "");
    await fetchParticipatingCampaigns();
  }
  await fetchCampaigns(tenant_code || "");
  await filterPromotedCampaigns();
  updateLoading(false);
};

export const fetchEarndCampaignData = async () => {
  fetchEarnedCampaigns();
  resetEarnedCampaignData();
  filterEarnedCampaigns("all");
};

export const onSuccessAppliedPoint = async (tenant_code: string) => {
  await fetchCampaignData(tenant_code ?? "", true);
  fetchEarndCampaignData();
};

export const isAquiredAllReward = (campaign: Campaign) => {
  if (campaign?.rewards?.some((reward) => reward.is_repetition === true))
    return false;
  const rewardIds = campaign?.matchedRewards?.map(
    (reward) => reward.campaign_reward_id,
  );
  const campaignRewardIds = campaign?.rewards?.map(
    (campaignReward) => campaignReward.id,
  );
  return (
    campaignRewardIds?.every((campaignRewardId) =>
      rewardIds?.includes(campaignRewardId),
    ) && campaign.rewards.length === rewardIds.length
  );
};

export const isAquiredReward = (
  reward: Reward,
  is_repetition: boolean,
  matchedRewards: Reward[],
) => {
  if (is_repetition) return false;
  const rewardIds = matchedRewards.map((reward) => reward.campaign_reward_id);
  return rewardIds.includes(reward.id);
};

export const filterWithinEventPeriodCampaigns = (campaigns: Campaign[]) => {
  const now = new Date();
  return campaigns.filter((campaign) => {
    return (
      new Date(campaign.publication_period_start) < now &&
      new Date(campaign.publication_period_end) > now
    );
  });
};

export const filterAndSortPromotedCampaigns = (campaigns: Campaign[]) => {
  return campaigns
    .filter((campaign: Campaign) => {
      return campaign.is_promoted && campaign.promote_number <= 3;
    })
    .sort((a: Campaign, b: Campaign) => {
      return a.promote_number - b.promote_number;
    });
};

export const excludeCategories = (campaigns: Campaign[]) => {
  const categories = campaigns
    .map((campaign: Campaign) => {
      return campaign.campaign_categories;
    })
    .reduce((acc: any, item: any) => {
      if (!acc.includes(item)) {
        acc.push(...item);
      }
      return acc;
    }, []);
  const uniqueCategories = [...new Set(categories)];
  return uniqueCategories;
};

export const isSameRewardPeriod = (rewards: CampaignReward[]) => {
  return rewards?.every(
    (reward) =>
      reward.reward_validity_period_start ===
        rewards[0].reward_validity_period_start &&
      reward.reward_validity_period_end ===
        rewards[0].reward_validity_period_end,
  );
};
