import { useCallback, useState } from "react";
import { fetchCampaignData } from "../../../../common/utils/campaign";
import {
  defaultLINEModalProps,
  defaultModalProps,
} from "../../../ui/modal/initial";
import { ModalLINEProps, ModalProps } from "../../../ui/modal/types";
import { Campaign } from "../campaign.type";
import { joinCampaign } from "../redux/campaign/store";

export const useCampaign = (
  campaign: Campaign,
  tenant_code: string | undefined,
  isLogin: boolean,
) => {
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);
  const [modalLINEProps, setModalLINEProps] = useState<ModalLINEProps>(
    defaultLINEModalProps,
  );
  const handleJoin = () => {
    if (campaign?.meta_data?.entry_qualifications?.is_over_twenty_year_old)
      return onClickJoin();
    if (campaign?.line_settings?.allow_adding_friends) return onClickAddLINE();
    return onLineLink();
  };

  const onClickAddLINE = useCallback(() => {
    setModalLINEProps({
      isShow: true,
      title: "LINE友だち追加",
      subTitle: "",
      image_url: "",
      onClose() {
        setModalLINEProps(defaultLINEModalProps);
      },
      contents: campaign?.line_settings?.expository_text ?? "",
      line_friends: campaign?.line_friends,
      onClick: (val?: string) => {
        return onLineLink(val ?? "");
      },
    });
  }, [modalLINEProps]);

  const onClickJoin = useCallback(() => {
    setModalProps({
      title: "あなたは20歳以上ですか？",
      subTitle: "",
      contents: "本キャンペーンは20歳以上を対象としたキャンペーンです",
      onClose() {
        setModalProps(defaultModalProps);
      },
      buttons: [
        {
          name: "20歳以上",
          callback: () => {
            if (campaign?.line_settings?.allow_adding_friends) {
              return onClickAddLINE();
            } else {
              return onLineLink();
            }
          },
        },
        {
          name: "20歳未満",
          styling: "border",
          color: "#000000",
          callback: () => {
            setModalProps({
              isShow: true,
              title: "20歳未満は参加できません",
              subTitle: "",
              image_url: "",
              onClose() {
                setModalProps(defaultModalProps);
              },
              contents: "",
            });
          },
        },
      ],
      isShow: true,
      hideClose: true,
    });
  }, [onClickAddLINE]);

  const onLineLink = useCallback((line_friend_url?: string) => {
    if (tenant_code) {
      joinCampaign(campaign.id, tenant_code, async (res) => {
        await fetchCampaignData(tenant_code ?? "", isLogin);
        if (res)
          window.location.replace(`/${tenant_code}/campaign?tab=participating`);
      });
    }
    // LINE友達追加設定がtrueの場合、かつ、LINE友だち選択時にLINE友達追加画面に遷移する
    if (line_friend_url) {
      window.open(line_friend_url, "_blank", "noopener noreferrer");
    }
  }, []);

  const caluclatedMile = (campaign: Campaign): number => {
    if (campaign?.accumulated_mileage === 0 || campaign.next_mileage <= 0) {
      return 0;
    }
    const numerator = campaign?.accumulated_mileage - campaign?.before_mileage;
    const denominator = campaign.next_mileage - campaign?.before_mileage;
    if (numerator <= 0) return 0;
    if (denominator <= 0) return 0;
    const calc = numerator / denominator;

    if (calc > 1) return 100;
    return calc * 100;
  };

  const calculateMileRemaining = (
    next_mileage: number,
    accumulated_mileage: number,
  ): number => {
    const calc = next_mileage - accumulated_mileage;
    return calc > 0 ? calc : 0;
  };

  return {
    handleJoin,
    onClickAddLINE,
    onClickJoin,
    onLineLink,
    modalProps,
    modalLINEProps,
    setModalProps,
    caluclatedMile,
    calculateMileRemaining,
  };
};
